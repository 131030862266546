/**
 * App Information
 */
export const APP_INFO = {
  name: 'Business Suite',
  root: '/a/volkovlabs-app',
};

/**
 * Routes
 */
export const APP_ROUTES = {
  plugins: `${APP_INFO.root}`,
};
